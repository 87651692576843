import AddressAutofill from '../../AddressAutofill'
import DateRangeInput from '../../DateRangeInput'
import Template from '../../Template'
import * as style from './style.module.scss'
import { navigate } from 'gatsby'
import Link from 'gatsby-link'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import { connectSearchBox } from 'react-instantsearch-dom'
import {
  unstable_useFormState as useFormState,
  unstable_Form as Form,
  unstable_FormLabel as FormLabel,
} from 'reakit/Form'

const Doggos = (props) => {
  const form = useFormState({})
  const locationRef = React.useRef()

  return (
    <Template>
      <div className={style.container}>
        <div className={style.heroContainer}>
          <h1 className={style.h1}>
            Book the best care for your dog while you're away
          </h1>
          <h3 className={style.subline}>
            Browse local dog sitters, including businesses and boarding
            facilities
          </h3>
        </div>

        <form
          {...form}
          noValidate={false}
          className={style.form}
          onSubmit={(event) => {
            event.preventDefault()
            const data = new FormData(event.target)
            props.refine(data.get('query'))
            navigate(`/search/?l=${data.get('l')}&query=${data.get('query')}`)
          }}
        >
          <div className={style.inputRow}>
            <div className={style.inputGroup}>
              <FormLabel className={style.label}>
                What area should we search?{' '}
              </FormLabel>
              <div className={style.inputWrapper}>
                <AddressAutofill
                  {...form}
                  placeholder='Search by city or state'
                  name='city'
                  placeSelect={(place) => {
                    locationRef.current.value = `${place.properties.lat},${place.properties.lon}`
                  }}
                />
                <input ref={locationRef} type='hidden' name='l' />
              </div>
            </div>
            <div className={style.inputGroup}>
              <FormLabel className={style.label}>
                Drop-off and pick-up dates
              </FormLabel>
              <div className={style.inputRow}>
                <DateRangeInput attribute='unavailability' />
              </div>
            </div>
          </div>
          <div className={style.inputRow}>
            <button className={style.button} type='submit'>
              Browse dog sitters
            </button>
          </div>
        </form>
        <div className={style.howItWorks}>
          <h2 className={style.h2}>How it works</h2>
          <div className={style.blockContainer}>
            <div className={style.block}>
              <div className={style.image}>
                <StaticImage placeholder='none' src='1.png' height='220' />
              </div>
              <h3 className={style.h3}>1. Browse sitters</h3>
              <p>
                Find pet care you know your dog will love with filters for
                location, accommodation type and exercise & behavior needs
              </p>
            </div>
            <div className={style.block}>
              <div className={style.image}>
                <StaticImage placeholder='none' src='2.png' height='220' />
              </div>
              <h3 className={style.h3}>2. Chat and book</h3>
              <p>
                Easily communicate with potential sitters using our super fast
                real-time chat, with alerts via push, SMS and email
              </p>
            </div>
            <div className={style.block}>
              <div className={style.image}>
                <StaticImage placeholder='none' src='3.png' height='220' />
              </div>
              <h3 className={style.h3}>3. Get updates</h3>
              <p>
                View your dog's timeline updates, request photos or videos of
                your dog's day, or change the details of your stay with ease
              </p>
            </div>
          </div>
        </div>
        <div className={style.textSection}>
          <h2 className={style.h2}>Platform features</h2>
          <div className={style.textBlockContainer}>
            <div className={style.textBlock}>
              <h3 className={style.h3}>Live chat during your dog's stay</h3>
              <p>
                Our innovative live chat shows your dog's activity throughout
                their stay with your sitter, automatically! You can also request
                photo & video updates anytime.
              </p>
            </div>
            {/* <div className={style.textBlock}>
              <h3 className={style.h3}>Custom services</h3>
              <p>
                Does your dog need breakfast cooked in the morning? 30 minutes
                of treadmill time? No problem! Custom services are what we are
                built on, so you can easily add or change your dog's
                reservation.
              </p>
            </div> */}
            <div className={style.textBlock}>
              <h3 className={style.h3}>Payment protection</h3>
              <p>
                Any reservation can be canceled within 48 hours of your booking
                and receive an instant refund with 0 fees or deductions from
                your original payment method.
              </p>
            </div>
            <div className={style.textBlock}>
              <h3 className={style.h3}>Pet protection</h3>
              <p>
                All reservations automatically include 24/7 support, and $1,000
                in veterinary care reimbursement for <strong>FREE</strong>.
                Additional coverage is also available with no breed, age, or
                size restrictions.
              </p>
            </div>
            <div className={style.textBlock}>
              <h3 className={style.h3}>Low cost</h3>
              <p>
                Doggos.com has a simple <strong>5% fee</strong> on all bookings,
                making us the most affordable way to find a dog sitter.
              </p>
            </div>
          </div>
        </div>
        <div className={style.container}>
          <div className={style.textContainer}>
            <h2>One platform, more options </h2>
            <p>
              Compare and chat with local boarding facilities and in-home dog
              sitters, all in one simple website
            </p>
            <div className={style.buttonContainer}>
              <Link className={style.buttonGreen} to={'/search/'}>
                Find a dog sitter
              </Link>
              <Link className={style.buttonWhite} to={'/about/'}>
                About us
              </Link>
            </div>
          </div>
        </div>
        <div className={style.appDownload}>
          <div className={style.textContainer}>
            <h2>No app download required</h2>
            <p>
              Doggos works on any internet-connected phone, tablet, or computer.
              Simply login and check on your pup from any device.
            </p>
          </div>
          <div className={style.imageContainer}>
            <div className={style.deviceImageDesktop}>
              <StaticImage
                placeholder='none'
                src='cropped_devices.png'
                height='412'
              />
            </div>
            <div className={style.deviceImageMobile}>
              <StaticImage
                placeholder='none'
                src='cropped_devices.png'
                height='250'
              />
            </div>
          </div>
        </div>
      </div>
    </Template>
  )
}

export default connectSearchBox(Doggos)
